export const COURSE_CARD_PREFETCH_FEATURE_CODE = 'course_card_prefetch';
export const SHOW_CANCEL_ANYTIME_MESSAGING_FOR_PP = 'show_cancel_anytime_messaging_for_pp';
export const UPDATE_PERSONAL_PLAN_TOPIC_PAGE = 'update_personal_plan_topic_page';
export const UPDATE_UDEMY_BUSINESS_TOPIC_PAGE = 'update_udemy_business_topic_page';
export const MARKETPLACE_SUBSCRIPTION_OFFER = 'marketplace_subscription_offer';
export const USE_STANDARDIZE_MOBILE_BROWSE_CAROUSEL = 'use_standardize_mobile_browse_carousel';
export const ADD_SUBHEADINGS_TO_CAT_SUBCAT_TOPIC_RECOMMENDATIONS =
    'add_subheadings_to_cat_subcat_topic_recommendations';
export const SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE =
    'subscription_catalog_differentiator_feature';
export const DELAY_COURSE_CARD_QUICK_VIEW_BOX = 'delay_course_card_quick_view_box';
export const HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX =
    'hide_wishlist_button_on_course_card_quick_view_box';
export const COURSE_CARDS_OPEN_IN_NEW_TAB = 'course_cards_open_in_new_tab';
export const BROWSE_AGGREGATOR_PAGE_ENABLED = 'browse_aggregator_page';
export const AUTH_FULL_PAGE_REDIRECT = 'auth_full_page_redirect';
export const CERTIFICATION_BUNDLE_ON_TOPIC_CLP = 'certification_bundle_on_topic_clp';
export const CERTIFICATION_BUNDLE_EXPANSION = 'certification_bundle_expansion';
export const USE_VERTICAL_MOBILE_BROWSE_LAYOUT = 'use_vertical_mobile_browse_layout';
export const REMOVE_SMARTBAR_FROM_BROWSE_PAGES = 'remove_smartbar_from_browse_pages';
export const REMOVE_SMARTBAR_FROM_BROWSE_PAGES_ROUTES = [
    'career_academies_landing_page',
    'all_careers_page',
    'discovery_category',
    'discovery_featured_topics',
    'discovery_subcategory',
    'discovery_topic',
];
export const GENERIC_BUNDLES_ON_TOPICS = 'generic_bundles_on_topics';
export const QUICK_VIEW_BOX_FEATURE = 'quick_view_box_feature';
export const DESKTOP_POST_ADD_TO_CART_BEHAVIOR = 'desktop_post_add_to_cart_behavior';
export const COURSE_GUIDANCE = 'course_guidance_feature';
