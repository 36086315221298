import {AxiosError, AxiosResponse} from 'axios';

import {udApi, version} from '@udemy/ud-api';

import {getRuntimeConfig} from 'src/pages/api/config.api';

export const configureUdApi = () => {
    udApi.configure({
        getBaseUrl: () => {
            // If this is a server-side request, we want to directly call the full
            // monolith URL. Otherwise, we can call the API just using the pathname
            // prefixed with a base path
            // (e.g. /frontends-marketplace-experience/api-2.0/... in dev)
            const isServer = typeof window === 'undefined';
            return isServer
                ? `${getRuntimeConfig('MONOLITH_URL')}/${version}/`
                : `${process.env.APP_BASE_PATH}${version}/`;
        },
    });

    // Sanitize the AxiosError.
    // This is to prevent serializing huge error objects, which costs memory and log space.
    // See https://github.com/axios/axios/issues/2624 for more info.
    udApi.interceptors.response.use(undefined, (error: AxiosError) => {
        const request = error.request || {};
        const sanitizedRequest = {
            _header: request._header,
            method: request.method,
            protocol: request.protocol,
            host: request.host,
            path: request.path,
        };
        const response = error.response;
        const sanitizedResponse = {
            headers: response?.headers,
            status: response?.status,
            statusText: response?.statusText,
            data: response?.data,
        };
        return Promise.reject(
            new AxiosError(
                error.message,
                error.code,
                error.config,
                sanitizedRequest,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sanitizedResponse as unknown as AxiosResponse<unknown, any>,
            ),
        );
    });

    return udApi;
};

export const udemyApi = (() => {
    return configureUdApi();
})();
